"use client";
import { House, List } from "@phosphor-icons/react/dist/ssr";
import Link from "next/link";
import { useState } from "react";
import { twJoin } from "tailwind-merge";
import NavBar from "./NavBar";

export default function Header() {
	const [menuExpanded, setMenuExpanded] = useState(false);

	return (
		<header className="sticky top-0 z-10 h-14 border-b border-b-gray-800/70 bg-primary-800/75 shadow-[0_5px_15px_rgba(0,0,0,0.85)] backdrop-blur-sm">
			<div className="container mx-auto flex h-full max-w-4xl items-center justify-between space-x-6 px-4">
				<div className="flex items-center space-x-6">
					<Link className="group flex space-x-4" href="/">
						<div className="relative size-7 overflow-hidden">
							<img
								alt="TakeTenBot Logo"
								className="group-hover:-mt-10 absolute top-0 size-7 transition-all duration-300"
								src="/favicon.ico"
							/>

							<House
								className="absolute top-0 mt-10 ml-1 size-6 text-gray-400 transition-all duration-300 group-hover:mt-0"
								weight="bold"
							/>
						</div>

						<h1 className="hidden font-bold text-lg md:block">TakeTenBot</h1>
					</Link>

					<div className="hidden md:block">
						<NavBar />
					</div>
				</div>

				<button
					className="block px-4 py-2 md:hidden"
					onClick={() => setMenuExpanded((currentValue) => !currentValue)}
					type="button"
				>
					<List />
				</button>
			</div>

			<div
				className={twJoin(
					"backdrop-blur",
					"bg-inherit",
					"duration-300",
					"origin-top",
					"overflow-hidden",
					"transition-all",
					menuExpanded ? "h-screen scale-100 opacity-100" : "sm:h-0 sm:scale-90 sm:opacity-0",
				)}
			>
				<div className="container mx-auto *:*:block *:*:space-x-0 *:*:divide-y *:*:divide-primary-700/50 *:*:*:py-2">
					<NavBar />
				</div>
			</div>
		</header>
	);
}
