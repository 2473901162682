"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useCallback, type PropsWithChildren } from "react";
import { twJoin } from "tailwind-merge";

function NavLink({ active, children, href }: PropsWithChildren<{ active: boolean; href: string }>) {
	return (
		<Link
			className={twJoin(
				"block",
				"rounded-lg",
				"px-4",
				"py-2",
				"transition-all",
				"duration-500",
				active ? "bg-primary-700" : "hover:bg-primary-700",
			)}
			href={href}
		>
			{children}
		</Link>
	);
}

export default function NavBar() {
	const pathname = usePathname();
	const isRouteActive = useCallback(
		(path: string, exact?: boolean) => (exact ? path === pathname : path.startsWith(pathname)),
		[pathname],
	);

	return (
		<nav className="text-sm">
			<ul className="flex space-x-4">
				<li>
					<NavLink active={isRouteActive("/roadmap", true)} href="/roadmap">
						Roadmap
					</NavLink>
				</li>
				<li>
					<NavLink active={isRouteActive("/faq", true)} href="/faq">
						FAQ
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}
